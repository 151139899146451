:root {
  --primary: #521262;
  --dark: #1A1A1A;
  --white: #FFFFFF;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: var(--dark);
}

h1, h3, h4, h6, p {
  margin-bottom: 0rem;
}

h3 {
  margin-bottom: 1rem;
}

h3 {
  font-weight: bold;
}

.navbar {
  background-color: var(--white);
  border-bottom: 1px solid var(--primary);
}

.nav-link {
  color: var(--primary);
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  font-weight: bold;
}

.nav-link:hover,
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  background-color: var(--primary);
  color: var(--white)!important;
}

.nav-link:focus {
  color: var(--white);
}

.navbar-toggler {
  border: none;
}

.navbar-toggler img {
  width: 1.5rem;
  height: 1.5rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-brand {
  color: var(--white);
}

.startSpace {
  padding-top: 3.563rem;
  height: 35rem;
  background-image: url('./imgs/managment-platform.jpg');
  background-position: 50% 10%;
  background-size: cover;
  text-align: center;
}

.coflowSpace {
  height: 15rem;
  background-color: var(--white);
  color: var(--primary);
  text-align: center;
}

.coflowSpace h5 {
  font-weight: bold;
}

.howWorks {
  background-image: url('./imgs/devices.jpg');
  background-position: center;
  background-size: cover;
}

.columnText {
  border-bottom: 1px solid var(--primary);
  border-top: 1px solid var(--primary);
}

.howItWorkSpace h3 {
  color: var(--primary);
  margin-top: 2rem;
}

.howItWorkSpace p {
  margin-bottom: 2rem;
}

.teamSpace {
  padding: 2rem 0rem;
}

.teamSpace h3 {
  color: var(--primary);
}

.teamDivision {
  padding-top: 1.5rem;
}

.card {
  border: 1px dashed var(--primary);
}

.card img {
  margin: 0 auto;
  margin-top: 1.5rem;
}

.footer {
  height: 5rem;
  background-color: var(--primary);
  color: var(--white);
}

@media screen and (max-width: 992px) {
  .nav-link {
    padding-left: 0.5rem;
  }

  .teamDivision {
    padding-top: 0rem;
  }

  .coflowSpace p {
    margin: 2rem 0;
  }

  h5 {
    margin: 2rem auto;
  }

  .howWorks {
    height: 20rem;
  }

  .columnText {
    border-bottom: none;
  }
}